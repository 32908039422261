<template>
  <!-- 切换为中文 -->
  <a-config-provider :locale="zhCN">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>
<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import { ConfigProvider } from 'ant-design-vue'

dayjs.locale('zh-cn')
// import moment from 'moment'
// import 'moment/locale/zh-cn'

// moment.locale('zh-cn')
export default {
  name: 'App',
  data() {
    return {
      zhCN,
      dayjs
    }
  },
  mounted() {
    ConfigProvider.config({
      theme: {
        primaryColor: '#FF6829'
      }
    })
  }
}
</script>
<style lang="scss">
#app {
}
</style>
