<template>
  <!-- 视图区域 -->
  <div>
    <!-- <router-view v-slot="{ Component, route }">
      <keep-alive v-if="route.meta.keepAlive">
        <component :is="Component" />
      </keep-alive>
      <component v-else :is="Component" />
    </router-view> -->
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'AppMain',
  setup() {}
}
</script>
<style lang="scss" scoped></style>
